import { NewsDTO } from '../../dtos/NewsDTO';

export const news: NewsDTO[] = [
  {
    id: 'post20241001',
    title:
      'Paper published in the IEEE Journal of Translational Engineering in Health and Medicine',
    description:
      'We are pleased to announce that we have a paper accepted for the IEEE Journal of Translational Engineering in Health and Medicine.',
    imageUrl: '/assets/images/IEEE-EMBS_logo.jpg',
    createdAt: new Date('2024-10-30T00:00:00'),
  },
  {
    id: 'post20240901',
    title:
      'PathoSpotter in the XXXII Brazilian Congress of Nephrology',
    description:
      'Researchers of the PathoSpotter project presented six papers in the main conference about nephrology in Brazil. The conference tooke placed in Salvador-Bahia from September 25-28th, 2024. More details in https://congressonefro.com.br/index.php.',
    imageUrl: '/assets/images/logoCongressoNefrologia2024.png',
    createdAt: new Date('2024-09-25T00:00:00'),
  },
  {
    id: 'post20240501',
    title:
      'PathoSpotter in the 34th Brazilian Congress of Pathology',
    description:
      'Researchers of the PathoSpotter project presented six papers and two main conferences in the main conference about pathology in Brazil. The conference tooke placed in Belem-Para from May 28th to June 1st, 2024. More details in https://congressodepatologia.org.br/',
    imageUrl: '/assets/images/logoCongressoPatologia2024.png',
    createdAt: new Date('2024-05-28T00:00:00'),
  },
  {
    id: 'post20230425',
    title:
      'Paper published in the Computer Methods in Biomechanics and Biomedical Engineeringjournal',
    description:
      'We are pleased to announce that we have a paper accepted for the "Imaging & Visualization" section of the Computer Methods in Biomechanics and Biomedical Engineering.',
    imageUrl: '/assets/images/cmbbeiv.png',
    createdAt: new Date('2023-04-25T00:00:00'),
  },
  {
    id: 'post20230410',
    title:
      'Paper published in the Clinical Journal of the American Society of Nephrology',
    description:
      'We are pleased to announce that we have a paper accepted in the Clinical Journal of the American Nephrology Society.',
    imageUrl: '/assets/images/cjasn.png',
    createdAt: new Date('2023-04-10T00:00:00'),
  },
  {
    id: 'post20230303',
    title:
      'Matéria sobre o PathoSpotter publicada no jornal da UFBA',
    description:
      'Com o título "Projeto utiliza inteligência artificial para aperfeiçoar diagnóstico de biópsias e ganha prêmio de medicina" o artigo registra como o PathoSpotter pode ser usado no auxílio ao diagnóstico em nefropatologia. O artigo também comenta sobre a trajetória e desafios do projeto ao longo de sua existência. A matéria completa pode ser encontrada no site do jornal em https://www.edgardigital.ufba.br/?p=25445.',
    imageUrl: '/assets/images/edgardigital.png',
    createdAt: new Date('2023-03-03T00:00:00'),
  },
  {
    id: 'post20221208',
    title:
      'Paper published in the Computational Statistics international journal.',
    description:
      'We are pleased to announce that we have a paper published in the 2022 edition of the Computational Statistics journal.',
    imageUrl: '/assets/images/compStats.png',
    createdAt: new Date('2022-12-08T00:00:00'),
  },
  {
    id: 'post20221123',
    title:
      'Pathospotter received a prize in the 2022 edition of the DASA Medical Innovation Award.',
    description:
      'The DASA Medical Innovation Award curated by VEJA SAÚDE seeks to recognize projects, institutions and health professionals that make a difference in the scientific, clinical and care areas. We want to discover ideas and achievements with innovative potential capable of changing the lives and health of Brazilians. Further information is available at the home-page of the contest (https://premiodeinovacaomedica.com.br).',
    imageUrl: '/assets/images/premioDASA.png',
    createdAt: new Date('2022-11-23T00:00:00'),
  },
  {
    id: 'post20221122',
    title:
      'Book chapter published in the "Intelligent Systems - BRACIS 2022" from Springer.',
    description:
      'We are pleased to announce that we have a chapter published in the Proceedings of 11th Brazilian Conference in Intelligent Systems.',
    imageUrl: '/assets/images/bracis2022.png',
    createdAt: new Date('2022-11-22T00:00:00'),
  },
  {
    id: 'post20221029',
    title:
      'Book chapter published in the book "Innovations in Nephrology" from Springer Nature.',
    description:
      'We are pleased to announce that we have a chapter published in the boo Innovations in Neprhology from Springer Nature.',
    imageUrl: '/assets/images/innovationsInNephrology.png',
    createdAt: new Date('2022-10-29T00:00:00'),
  },
  {
    id: 'post20220909',
    title:
      'Paper accepted in Computer Methods in Biomechanics and Biomedical Engineering: Imaging & Visualization',
    description:
      'We are pleased to announce that we have a paper accepted to a special issue of the Computer Methods in Biomechanics and Biomedical Engineework is entitled “Boundary-aware glomerulus segmentation: Toward one-to-many stain generalization” and proposes a novel convolutional neural network for glomerulus segmentation: the DS-FNet.',
    imageUrl: '/assets/images/computerizedmedicalImagingAndGraphics.jpg',
    createdAt: new Date('2022-08-11T00:00:00'),
  },
  {
    id: 'post20220806',
    title: 'Award in the 33rd Brazilian Conference on Pathology',
    description:
      'Matheus Pimentel, from the PathoSpotter\'s team, received an award with the poster "Quais Características Visuais das Imagens São Utiliadas por Pessoas para Agrupar Lesões Glomerulares? Um estudo de Agrupamento de Imagens Histológicas por Similaridade", presented in the 33rd Brazilian Conference on Pathology, which ocurred from Aug. 3-6, 2022, at Foz do Iguaçu - Brazil. Available at https://congressodepatologia.org.br',
    imageUrl: '/assets/images/awardMatheus.png',
    createdAt: new Date('2022-08-06T00:00:00'), // year-month-dayThour:minute:second
  },
  {
    id: 'post20220811',
    title:
      'Paper accepted in the 22nd Brazilian Symposium on Computing Applied to Health',
    description:
      'We are pleased to announce that we have a paper was accepted in the 22nd Brazilian Symposium on Computing Applied to Health.',
    imageUrl: '/assets/images/LOGOSBCASSBC4.png',
    createdAt: new Date('2022-08-11T00:00:00'),
  },
];

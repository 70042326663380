import { PublicationsDTO } from '../../dtos/PublicationDTO';

// Modelo do registro de publicações. As entradas são agrupadas por ano. Cada ano é um
// item do tipo publicationPeriod que por sua vez tem um Array "publications". O campo
// id é uma chave que serve apenas para efeito de indexação e pode ter qualquer valor.
// {
//   publicationPeriod: {
//     year: '2023',
//     publications: [
//       {
//         id: 'f70bb35e-ea08-48c9-b94e-895d7893f5e3',
//         titlePubli: {
//           title:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
//           link:
//             'https://github.com/LACAD/pathospotter-site',
//         },
//         description:
//           'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
//         links: {
//           sourceCode:
//             'https://github.com/Solvum-Tecnologia/pathospotter-front',
//           downloadCode:
//             'https://github.com/Solvum-Tecnologia/pathospotter-front/archive/refs/heads/main.zip',
//         },
//         abstract: {
//           title: 'Abstract',
//           description:
//             'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
//         },
//       },
//     ],
//   },
// },

export const publications: PublicationsDTO[] = [
  {
    publicationPeriod: {
      year: '2024',
      publications: [
        {
          id: '2024-1',
          titlePubli: {
            title:
              'Enhancing Podocyte Degenerative Changes Identification With Pathologist Collaboration: Implications for Improved Diagnosis in Kidney Diseases',
            link:
              'https://ieeexplore.ieee.org/document/10675352',
          },
          description:
            'G. O. Barros et al., "Enhancing Podocyte Degenerative Changes Identification With Pathologist Collaboration: Implications for Improved Diagnosis in Kidney Diseases," in IEEE Journal of Translational Engineering in Health and Medicine, vol. 12, pp. 635-642, 2024, doi: 10.1109/JTEHM.2024.3455941.',
          links: {
            sourceCode: '',
            downloadCode:
              'https://ieeexplore.ieee.org/document/10675352',
          },
          abstract: {
            title: 'Abstract',
            description: 'Podocyte degenerative changes are common in various kidney diseases, and their accurate identification is crucial for pathologists to diagnose and treat such conditions. However, this can be a difficult task, and previous attempts to automate the identification of podocytes have not been entirely successful. To address this issue, this study proposes a novel approach that combines pathologists’ expertise with an automated classifier to enhance the identification of podocytopathies. The study involved building a new dataset of renal glomeruli images, some with and others without podocyte degenerative changes, and developing a convolutional neural network (CNN) based classifier. The results showed that our automated classifier achieved an impressive 90.9% f-score. When the pathologists used as an auxiliary tool to classify a second set of images, the medical group’s average performance increased significantly, from 91.4±12.5 % to 96.1±2.9 % of f-score. Fleiss’ kappa agreement among the pathologists also increased from 0.59 to 0.83. Conclusion: These findings suggest that automating this task can bring benefits for pathologists to correctly identify images of glomeruli with podocyte degeneration, leading to improved individual accuracy while raising agreement in diagnosing podocytopathies. This approach could have significant implications for the diagnosis and treatment of kidney diseases. Clinical impact: The approach presented in this study has the potential to enhance the accuracy of medical diagnoses for detecting podocyte abnormalities in glomeruli, which serve as biomarkers for various glomerular diseases.',
          },
        },
      ],
    },
  },
  {
    publicationPeriod: {
      year: '2023',
      publications: [
        {
          id: '2023-2',
          titlePubli: {
            title:
              'Mouse-to-human transfer learning for glomerulus segmentation',
            link:
              'https://doi.org/10.1080/21681163.2023.2204978',
          },
          description:
            'Luiz Souza, Jefferson Silva, Paulo Chagas, Angelo Duarte, Washington LC-dos Santos & Luciano Oliveira (2023) Mouse-to-human transfer learning for glomerulus segmentation, Computer Methods in Biomechanics and Biomedical Engineering: Imaging & Visualization, DOI: 10.1080/21681163.2023.2204978',
          links: {
            sourceCode: '',
            downloadCode:
              'https://www.tandfonline.com/doi/full/10.1080/21681163.2023.2204978',
          },
          abstract: {
            title: 'Abstract',
            description: 'Mice and humans share many features of internal organs. Therefore, mice are often used in experimental models of human diseases. Although this is commonplace in medicine, there is an avenue to go to explore it in computational pathology, where digital whole-slide images (WSIs) are the main objects of investigation. Considering the absence of research about knowledge transfer between mice and humans in machine learning modelling, we propose investigating the possibility of segmenting glomeruli in human WSIs by training deep learning models on mouse data only. A set of semantic segmenters were evaluated, which had their performance assessed on two data sets comprised of 18 mouse WSIs, and 42 human WSIs. Results demonstrated that U-Net 3+ achieved superior results on intra-data set: On the mouse data set, it reached the highest average score on HE-stained images, while on the human data set, this network achieved the highest average on all stains. U-Net 3+ also obtained the best results after being trained only on the mouse data set and predicting on the entire (train and test) human data set. Although all networks proved to be capable of segmenting intra-stain images, it was not possible to confirm the same results on inter-stain ones.',
          },
        },
        {
          id: '2023-1',
          titlePubli: {
            title:
              'Towards Real-World Computational Nephropathology',
            link:
              'https://journals.lww.com/cjasn/Abstract/9900/Towards_Real_World_Computational_Nephropathology.123.aspx',
          },
          description:
            'Calumby, Rodrigo T.; Duarte, Angelo A.; Angelo, Michele F.; Santos, Emanuele; Sarder, Pinaki; dos-Santos, Washington L.C.; Oliveira, Luciano R. Towards Real-World Computational Nephropathology. Clinical Journal of the American Society of Nephrology, April 07, 2023. | DOI: 10.2215/CJN.0000000000000168',
          links: {
            sourceCode: '',
            downloadCode:
              'https://journals.lww.com/cjasn/Abstract/9900/Towards_Real_World_Computational_Nephropathology.123.aspx',
          },
          abstract: {
            title: 'Abstract',
            description: '',
          },
        },
      ],
    },
  },
  {
    publicationPeriod: {
      year: '2022',
      publications: [
        {
          id: 'pub2022-7',
          titlePubli: {
            title:
              'Glomerulosclerosis detection with pre‐trained CNNs ensemble',
            link: 'https://doi.org/10.1007/s00180-022-01307-3',
          },
          description:
            'Santos, J., Silva, R., Oliveira, L. et al. Glomerulosclerosis detection with pre-trained CNNs ensemble. Comput Stat (2022). https://doi.org/10.1007/s00180-022-01307-3',
          links: {
            sourceCode: '',
            downloadCode: 'https://link.springer.com/article/10.1007/s00180-022-01307-3',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomerulosclerosis characterizes many conditions of primary kidney disease in advanced stages. Its accurate diagnosis relies on histological analysis of renal cortex biopsy, and it is paramount to guide the appropriate treatment and minimize the chances of the disease progressing to chronic stages. This article presents an ensemble approach composed of five convolutional neural networks (CNNs) - VGG-19, Inception-V3, ResNet-50, DenseNet-201, and EfficientNet-B2 - to detect glomerulosclerosis in glomerulus images. We fine-tuned the CNNs and evaluated several configurations for the fully connected layers. In total, we analyzed 25 different models. These CNNs, individually, demonstrated effectiveness in the task; however, we verified that the union of these five well-known CNNs improved the detection rate while decreasing the standard deviations of current techniques. The experiments were carried out in a data set comprised of 1,028 images, on which we applied data-augmentation techniques in the training set. The proposed CNNs ensemble achieved a near-perfect accuracy of 99.0% and kappa of 98.0%.',
          },
        },
        {
          id: 'pub2022-6',
          titlePubli: {
            title:
              'Glomerulosclerosis Identification Using a Modified Dense Convolutional Network',
            link: 'https://doi.org/10.1007/978-3-031-21686-2_17',
          },
          description:
            'Santos, J. et al. (2022). Glomerulosclerosis Identification Using a Modified Dense Convolutional Network. In: Xavier-Junior, J.C., Rios, R.A. (eds) Intelligent Systems. BRACIS 2022. Lecture Notes in Computer Science(), vol 13653. Springer, Cham. https://doi.org/10.1007/978-3-031-21686-2_17',
          links: {
            sourceCode: '',
            downloadCode: 'https://link.springer.com/chapter/10.1007/978-3-031-21686-2_17',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomerulosclerosis is a common kidney disease characterized by the deposition of scar tissue, which replaces the renal parenchyma, and is quantified by renal pathologists to indicate the presence and extent of renal damage. It is paramount to guide the appropriate treatment and minimize the chances of the disease progressing to chronic stages. Thus, to identify glomerulus with sclerosis, this article proposes a convolutional neural network (CNN) inspired by convolutional blocks of DenseNet-201 but with smaller dense layers. We analyzed five CNNs - VGG-19, Inception-V3, ResNet-50, DenseNet-201, and EfficientNet-B2 - to define the best CNN model and evaluated several configurations for the fully connected layers. In total, 25 different models were analyzed. The experiments were carried out in three datasets, composed of 1,062 images, on which we applied data-augmentation techniques in the training set. These CNNs demonstrated effectiveness in the task and achieved an accuracy of 92.7% and kappa of 85.3%, considered excellent.',
          },
        },
        {
          id: 'pub2022-5',
          titlePubli: {
            title:
              'PathoSpotter: Computational Intelligence Applied to Nephropathology',
            link: 'https://doi.org/10.1007/978-3-031-11570-7_16',
          },
          description:
            'Oliveira, L. et al. (2022). PathoSpotter: Computational Intelligence Applied to Nephropathology. In: Bezerra da Silva Junior, G., Nangaku, M. (eds) Innovations in Nephrology. Springer, Cham. https://doi.org/10.1007/978-3-031-11570-7_16',
          links: {
            sourceCode: '',
            downloadCode: 'https://link.springer.com/chapter/10.1007/978-3-031-11570-7_16',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Evidence-based medicine has received increasing attention. This type of medicine would have the benefit of using large data sets to investigate clinical–laboratory associations and validate hypotheses grounded on data. Pathology is one area that has been benefited from large data sets of images, having advances leveraged by computational pathology, which in turn relies in the advances of the methods conceived by the computational intelligence and the computer vision fields. This type of medicine would benefit of using large. By particularly considering kidney biopsies, computational nephropathology seeks to identify renal lesions from primary computer vision tasks that involve classification and segmentation of renal structures on histology images. In this context, this chapter aims at discussing some advances in computational nephropathology, contextualizing them in the scope of the PathoSpotter project. We also address current achievements and challenges, as well as dig in future prospects to the field.',
          },
        },
        {
          id: 'pub2022-4',
          titlePubli: {
            title:
              'Boundary-aware glomerulus segmentation: Toward one-to-many stain generalization',
            link: 'https://www.sciencedirect.com/science/article/abs/pii/S0895611122000775',
          },
          description:
            'Jefferson Silva, Luiz Souza, Paulo Chagas, Rodrigo Calumby, Bianca Souza, Izabelle Pontes, Angelo Duarte, Nathanael Pinheiro, Washington Santos, Luciano Oliveira. Boundary-aware glomerulus segmentation: Toward one-to-many stain generalization. Computerized Medical Imaging and Graphics, 2022, 102104, ISSN 0895-6111, https://doi.org/10.1016/j.compmedimag.2022.102104.',
          links: {
            sourceCode: '',
            downloadCode: 'https://www.sciencedirect.com/science/article/abs/pii/S0895611122000775?via%3Dihub',
          },
          abstract: {
            title: 'Abstract',
            description:
              'The growing availability of scanned whole-slide images (WSIs) has allowed nephropathology to open new possibilities for medical decision-making over high-resolution images. Diagnosis of renal WSIs includes locating and identifying specific structures in the tissue. Considering the glomerulus as one of the first structures analyzed by pathologists, we propose here a novel convolutional neural network for glomerulus segmentation. Our end-to-end network, named DS-FNet, combines the strengths of semantic segmentation and semantic boundary detection networks via an attention-aware mechanism. Although we trained the proposed network on periodic acid-Schiff (PAS)-stained WSIs, we found that our network was capable to segment glomeruli on WSIs stained with different techniques, such as periodic acid-methenamine silver (PAMS), hematoxylin-eosin (HE), and Masson trichrome (TRI). To assess the performance of the proposed method, we used three public data sets: HuBMAP (available in a Kaggle competition), a subset of the NEPTUNE data set, and a novel challenging data set, called WSI_Fiocruz. We compared the DS-FNet with six other deep learning networks: original U-Net, our attention version of U-Net called AU-Net, U-Net++, U-Net3Plus, ResU-Net, and DeepLabV3+. Results showed that DS-FNet achieved equivalent or superior results on all data sets: On the HuBMAP data set, it reached a dice score (DSC) of 95.05%, very close to the first place (95.15%); on the NEPTUNE and WSI_Fiocruz data sets, DS-FNet obtained the highest average DSC, whether on PAS-stained images or images stained with other techniques. To the best we know, this is the first work to show consistently high performance in a one-to-many-stain glomerulus segmentation following a thorough protocol on data sets from different medical labs.',
          },
        },
        {
          id: 'pub2022-3',
          titlePubli: {
            title:
              'PodNet: Ensemble-based Classification of Podocytopathy on Kidney Glomerular Images',
            link: 'https://www.scitepress.org/PublicationsDetail.aspx?ID=K9F8j45BVPA=&t=1',
          },
          description:
            'Barros, G.; Wanderley, D.; Rebouças, L.; Santos, W.; Duarte, A. and Vidal, F. (2022). PodNet: Ensemble-based Classification of Podocytopathy on Kidney Glomerular Images. In Proceedings of the 17th International Joint Conference on Computer Vision, Imaging and Computer Graphics Theory and Applications - Volume 5: VISAPP, ISBN 978-989-758-555-5; ISSN 2184-4321, pages 405-412. DOI: 10.5220/0010828600003124',
          links: {
            sourceCode: '',
            downloadCode: 'https://www.scitepress.org/PublicationsDetail.aspx?ID=K9F8j45BVPA=&t=1',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Podocyte lesions in renal glomeruli are identified by pathologists using visual analyses of kidney tissue sections (histological images). By applying automatic visual diagnosis systems, one may reduce the subjectivity of analyses, accelerate the diagnosis process, and improve medical decision accuracy. Towards this direction, we present here a new data set of renal glomeruli histological images for podocitopathy classification and a deep neural network model. The data set consists of 835 digital images (374 with podocytopathy and 430 without podocytopathy), annotated by a group of pathologists. Our proposed method (called here PodNet) is a classification method based on deep neural networks (pre-trained VGG19) used as features extractor from images in different color spaces. We compared PodNet with other six state-of-the-art models in two data set versions (RGB and gray level) and two different training contexts: pre-trained models (transfer learning from Imagenet) and from-scratch, both with hyperparameters tuning. The proposed method achieved classification results to 90.9% of f1-score, 88.9% precision, and 93.2% of recall in the final validation sets.',
          },
        },
        {
          id: 'pub2022-2',
          titlePubli: {
            title:
              'Computational pathology, new horizons and challenges for anatomical pathology.',
            link: 'https://surgexppathol.biomedcentral.com/articles/10.1186/s42047-022-00113-x',
          },
          description:
            'dos-Santos, W.L.C., de Freitas, L., Duarte, A.A. et al. Computational pathology, new horizons and challenges for anatomical pathology. Surg Exp Pathol 5, 10 (2022). doi:10.1186/s42047-022-00113-x',
          links: {
            sourceCode: '',
            downloadCode:
              'https://surgexppathol.biomedcentral.com/track/pdf/10.1186/s42047-022-00113-x.pdf',
          },
          abstract: {
            title: 'Abstract',
            description:
              'The emergence of digital pathology environments and the application of computer vision to the analysis of histological sections has given rise to a new area of Anatomical Pathology, termed Computational Pathology. Advances in Computational Pathology may substantially change the routine of Anatomical Pathology laboratories and the work profile of the pathologist.',
          },
        },
        {
          id: 'pub2022-1',
          titlePubli: {
            title:
              'Uncertainty-aware membranous nephropathy classification: A Monte-Carlo dropout approach to detect how certain is the model.',
            link: 'https://www.tandfonline.com/doi/full/10.1080/21681163.2022.2029573',
          },
          description:
            'Paulo Chagas, Luiz Souza, Izabelle Pontes, Rodrigo Calumby, Michele Angelo, Angelo Duarte, Washington Lc-Dos Santos & Luciano Oliveira (2022) Uncertainty-aware membranous nephropathy classification: A Monte-Carlo dropout approach to detect how certain is the model, Computer Methods in Biomechanics and Biomedical Engineering: Imaging & Visualization, doi: 10.1080/21681163.2022.2029573',
          links: {
            sourceCode: '',
            downloadCode: 'https://doi.org/10.1080/21681163.2022.2029573',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Membranous nephropathy (MN) is among the most common glomerular diseases that cause nephrotic syndrome in adults. To aid pathologists on performing the MN classification task, we proposed here a pipeline consisted of two steps. Firstly, we assessed four deep-learning-based architectures, namely, ResNet-18, MobileNet, DenseNet, and Wide-ResNet. To achieve more reliable predictions, we adopted and extensively evaluated a Monte-Carlo dropout approach for uncertainty estimation. Using a 10-fold cross-validation setup, all models achieved average F1-scores above 92%, where the highest average value of 93.2% was obtained by using Wide-ResNet. Regarding uncertainty estimation with Wide-ResNet, high uncertainty scores were more associated with erroneous predictions, demonstrating that our approach can assist pathologists in interpreting the predictions with high reliability. We show that uncertainty-based thresholds for decision referral can greatly improve classification performance, increasing the accuracy up to 96%. Finally, we investigated how the uncertainty scores relate to complexity scores defined by pathologists.',
          },
        },
      ],
    },
  },
  {
    publicationPeriod: {
      year: '2021',
      publications: [
        {
          id: 'pub2021-8',
          titlePubli: {
            title:
              'A hybrid of deep and textural features to differentiate glomerulosclerosis and minimal change disease from glomerulus biopsy images',
            link: 'https://www.sciencedirect.com/science/article/pii/S1746809421006170',
          },
          description:
            'Justino Duarte Santos, Rodrigo de M.S. Veras, Romuere R.V. Silva, Nayze L.S. Aldeman, Flávio H.D. Araújo, Angelo A. Duarte, João Manuel R.S. Tavares. A hybrid of deep and textural features to differentiate glomerulosclerosis and minimal change disease from glomerulus biopsy images. Biomedical Signal Processing and Control, Volume 70, 2021, ISSN 1746-8094, https://doi.org/10.1016/j.bspc.2021.103020.',
          links: {
            sourceCode: '',
            downloadCode: 'https://www.sciencedirect.com/science/article/pii/S1746809421006170',
          },
          abstract: {
            title: 'Abstract',
            description:
              'The minimal change disease (MCD) and glomerulosclerosis (GS) are two common kidney diseases. Unless adequately treated, these diseases leads to chronic kidney diseases. Accurate differentiation of these two diseases is of paramount importance as their methods of treatment and prognoses are different. Thus, this article propose a method capable of differentiating MCD from GS in glomerulus biopsies images based on a new hybrid deep and texture feature space. We conducted an extensive study to determine the best set of features for image representation. Our feature extraction methodology, which includes Haraliks and geostatistics texture descriptors and pre-trained CNNs, resulted in 13,476 characteristics. We then used mutual information to order the elements by importance and select the best set for differentiating MCD from GS using the random forest classifier. The proposed method achieved an accuracy of 90.3% and a Kappa index of 80.5%. Representation of glomerulus biopsy images with a hybrid of deep and textural features facilitates the accurate differentiation of GS and MCD.',
          },
        },
        {
          id: 'pub2021-7',
          titlePubli: {
            title:
              'PathoSpotter-Search: A Content-Based Image Retrieval Tool for Nephropathology.',
            link: 'https://www.inf.ufrgs.br/sibgrapi2021/awards.php#content',
          },
          description:
            'Ellen Aguiar, Rodrigo Calumby, Luciano Oliveira, Washington dos-Santos, and Angelo Duarte. PathoSpotter-Search: A Content-Based Image Retrieval Tool for Nephropathology. In: Proceedings of Workshop of Works in Progress (WiP) in 34th conference on Graphics, Patterns and Images (SIBGRAPI 2021). Best Work Award of the Workshop of Works in Progress (WiP). October 18-22, 2021.',
          links: {
            sourceCode: '',
            downloadCode: 'http://urlib.net/rep/8JMKD3MGPEW34M/45EJ2DS',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Nephropathologists typically organizes their repository of digital images of kidney biopsies in such a way that it is difficult to retrieve cases that have images similar to a picture under analysis. Having this in mind, we initiated the development of PathoSpotter-Search, a Content-Based Image Retrieval system for images of kidney biopsies. The system operates as a cloud service to avoid the need to install any software on the pathologist’s computer. Our approach combines a feature extractor followed by a similarity score calculator. We evaluated convolutional network (CN) architectures (VGG-16 (original and fine-tuned) and Inception-ResNet, and a network used in the proprietary classifier for glomerular hypercellularity), combined with Cosine and Euclidean distances as similarity scores. The first results have shown that the CN of the VGG- 16 combined with cosine distance yielded the best performance (precision ≈ 53%). To assess the usability and functionality of the PathoSpotter-Search as a cloud service, the system was tested by nephropathologists and proved to be useful as a tool for retrieving similar images from their local repositories. Currently, we are working to improve the system precision to at least 70%, and evaluating strategies to retrieve similar images based on segments or tiles of the query image.',
          },
        },
        {
          id: 'pub2021-6',
          titlePubli: {
            title:
              'Aprendizado Profundo na Classificação de Lesões Crescentes Glomerulares: modelos e condições.',
            link: 'https://www.inf.ufrgs.br/sibgrapi2021/',
          },
          description:
            'SILVA, J. M.; ANGELO, M. F.; SANTOS, W. L. C.; LOULA, A. Aprendizado Profundo na Classificação de Lesões Crescentes Glomerulares: modelos e condições. In: Proceedings of Workshop of Works in Progress (WiP) in 34th conference on Graphics, Patterns and Images (SIBGRAPI 2021). October 18-22, 2021',
          links: {
            sourceCode: '',
            downloadCode:
              'http://sibgrapi.sid.inpe.br/rep/8JMKD3MGPEW34M/45F7NEB',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomeruli are structures in the kidneys, responsible for filtering the blood, that can be affected by several lesions, such as the glomerular crescent, which is characterized by abnormal cell proliferation. In this work, different models and conditions for the application of deep learning are to evaluated in the task of classifying glomerular crescent histopathological images. The pre-trained networks Xception, InceptionV3, MobileNet, VGG16 and ResNet50 were compared, by applying to the classification of images with crescent vs normal glomeruli. Comparing the accuracy, precision, recall and F1-score of the models, the ResNet50 showed significantly better performance than the other networks, in all measures. The application of data augmentation did not result in a significant improvement in the results in this case. In an experiment of classification of crescent vs non-crescent glomeruli, adding images of three other lesions to the database, the application of Focal Loss presented greater accuracy and precision.',
          },
        },
        {
          id: 'pub2021-5',
          titlePubli: {
            title:
              'Avaliação de Modelos de Detecção de Objetos para Detectar Glomérulos em Imagens Histológicas.',
            link: 'https://www.inf.ufrgs.br/sibgrapi2021/',
          },
          description:
            'GALVAO, A. R.; REHEM, J. M. C.; SANTOS, W. L. C.; OLIVEIRA, L. R.; DUARTE, ANGELO; ANGELO, M. F. Avaliação de Modelos de Detecção de Objetos para Detectar Glomérulos em Imagens Histológicas. In: Proceedings of Workshop of Works in Progress (WiP) in 34th conference on Graphics, Patterns and Images (SIBGRAPI 2021). October 18-22, 2021',
          links: {
            sourceCode: '',
            downloadCode:
              'http://sibgrapi.sid.inpe.br/rep/8JMKD3MGPEW34M/45EK7EB',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomeruli are renal structures responsible for filtering blood and can be affected by lesions. Currently, computer systems to help identify these lesions have been developed, and thus, the detection of these glomeruli is of great importance. The objective of this work is to evaluate the performance of object detection models for the detection of glomeruli in digital histological images. Three models were evaluated: SM1 (SSD Mobilenet v1), FRR50 (Faster RCNN Resnet 50) and FRR101 (Faster RCNN Resnet 101), of which the FRR50 model obtained the best result, mAP=0.88.',
          },
        },
        {
          id: 'pub2021-4',
          titlePubli: {
            title:
              'Toward unbounded open-set recognition to say "I don\'t know" for glomerular multi-lesion classification.',
            link: 'https://www.spiedigitallibrary.org/conference-proceedings-of-spie/12088/120880Z/Toward-unbounded-open-set-recognition-to-say-I-dont-know/10.1117/12.2606273.short',
          },
          description:
            'Paulo Chagas, Luiz Souza, Rodrigo Calumby, Izabelle Pontes, Stanley Araújo, Angelo Duarte, Nathanael Pinheiro Jr., Washington Santos, and Luciano Oliveira "Toward unbounded open-set recognition to say "I don\'t know" for glomerular multi-lesion classification", Proc. SPIE 12088, 17th International Symposium on Medical Information Processing and Analysis, 120880Z (10 December 2021); https://doi.org/10.1117/12.2606273',
          links: {
            sourceCode: '',
            downloadCode:
              'https://www.spiedigitallibrary.org/conference-proceedings-of-spie/12088/120880Z/Toward-unbounded-open-set-recognition-to-say-I-dont-know/10.1117/12.2606273.short',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomeruli are histological structures located at the beginning of the nephrons in the kidney, having primary importance in the diagnosis of many renal diseases. Classifying glomerular lesions is time-consuming and requires experienced pathologists. Hence automatic classification methods can support pathologists in the diagnosis and decision-making scenarios. Recently most of state-of-the-art medical imaging classification methods have been based on deep-learning approaches, which are prone to return overconfident scores, even for out-of-distribution (OOD) inputs. Determining whether inputs are OOD samples is of underlying importance so as to ensure the safety and robustness of critical machine learning applications. Bearing this in mind, we propose a unified framework comprised of unbounded open-set recognition and multi-lesion glomerular classification (membranous nephropathy, glomerular hypercellularity, and glomerular sclerosis). Our proposed framework classifies the input into in- or OOD data: If the sample is an OOD image, the input is disregarded, indicating that the model “doesn’t know” the class; otherwise, if the sample is classified as in-distribution, an uncertainty method based on Monte-Carlo dropout is used for multi-lesion classification. We explored an energy-based approach that allows open-set recognition without fine-tuning the in-distribution weights to specific OOD data. Ultimately, our results suggest that uncertainty estimation methods (Monte-Carlo dropout, test-time data augmentation, and ensemble) combined with energy scores slightly improved our open-set recognition for in-out classification. Our results also showed that this improvement was achieved without decreasing the 4-lesion classification performance, with an F1-score of 0.923. Toward an unbounded open-set glomerular multi-lesion recognition, the proposed method also kept a competitive performance.',
          },
        },
        {
          id: 'pub2021-3',
          titlePubli: {
            title:
              'Deep-learning-based membranous nephropathy classification and Monte-Carlo dropout uncertainty estimation.',
            link: 'https://web.inf.ufpr.br/sbcas2021/',
          },
          description:
            'Paulo Chagas, Luiz Souza, Rodrigo Calumby, Angelo Duarte, Washington L.C. dos-Santos, Luciano Oliveira. Deep-learning-based membranous nephropathy classification and Monte-Carlo dropout uncertainty estimation. In: 2021 Brazilian Symposium on Computing Applied to Health (SBCAS). June 15-18, 2021',
          links: {
            sourceCode: '',
            downloadCode: '',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Membranous Nephropathy (MN) is one of the most common glomerular diseases that cause adult nephrotic syndrome. To assist pathologists on MN classification, we evaluated three deep-learning-based architectures, namely, ResNet-18, DenseNet and Wide-ResNet. In addition, to accomplish more reli- able results, we applied Monte-Carlo Dropout for uncertainty estimation. We achieved average F1-Scores above 92% for all models, with Wide-ResNet ob- taining the highest average F1-Score (93.2%). For uncertainty estimation on Wide-ResNet, the uncertainty scores showed high relation with incorrect classi- fications, proving that these uncertainty estimates can support pathologists on the analysis of model predictions.',
          },
        },
        {
          id: 'pub2021-2',
          titlePubli: {
            title:
              'PathoSpotter Classifier: Um Serviço Web para Auxílio à Classiﬁcação de Lesões em Glomérulos Renais.',
            link: 'https://web.inf.ufpr.br/sbcas2021/',
          },
          description:
            'Sarah P. Cerqueira, Ellen C. Aguiar, Angelo A. Duarte, Washington L.C. dos Santos, Luciano R. Oliveira, Michele F. Angelo. PathoSpotter Classifier: Um Serviço Web para Auxílio à Classiﬁcação de Lesões em Glomérulos Renais. In: 2021 Brazilian Symposium on Computing Applied to Health (SBCAS). June 15-18, 2021.',
          links: {
            sourceCode: '',
            downloadCode: '',
          },
          abstract: {
            title: 'Abstract',
            description:
              'In recent years, the PathoSpotter Project has developed and perfected classifiers to aid in the diagnosis of lesions in digital images of renal biopsies. Among the goals of the project is the availability of these classifiers so that pathologists can use them to facilitate their medical practice and also contribute to the improvement of the system. This work presents the architecture of the PathoSpotter Classifier, the Web service created by the PathoSpotter Project, and how the challenges faced in distributing the system for use by pathologists were overcome.',
          },
        },
        {
          id: 'pub2021-1',
          titlePubli: {
            title:
              'Automatic glomerulus detection in renal histological images.',
            link: 'https://doi.org/10.1117/12.2582201',
          },
          description:
            'Jonathan Moreira Cardozo Rehem, Washington Luís Conrado dos Santos, Angelo Amâncio Duarte, Luciano Rebouças de Oliveira, and Michele Fúlvia Angelo "Automatic glomerulus detection in renal histological images", Proc. SPIE 11603, Medical Imaging 2021: Digital Pathology, 116030K (15 February 2021); https://doi.org/10.1117/12.2582201.',
          links: {
            sourceCode: '',
            downloadCode: 'https://doi.org/10.1117/12.2582201',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomeruli are microscopic structures of the kidney affected in many renal diseases. The diagnosis of these diseases depends on the study by a pathologist of each glomerulus sampled by renal biopsy. To help pathologists with the image analysis, we propose a glomerulus detection method on renal histological images. For that, we evaluated two state-ofthe-art deep-learning techniques: single shot multibox detector with Inception V2 (SI2) and faster region-based convolutional neural network with Inception V2 (FRI2). As a result, we reached: 0.88 of mAP and 0.94 of F1-score, when using SI2, and 0.87 of mAP and 0.97 of F1-score, when using FRI2. On average, to process each image, FRI2 required 30.91s, while SI2 just 0.79s. In our experiments, we found that SI2 model is the best detection method for our task since it is 64% faster in the training stage and 98% faster to detect the glomeruli in each image.',
          },
        },
      ],
    },
  },
  {
    publicationPeriod: {
      year: '2020',
      publications: [
        {
          id: 'pub2020-1',
          titlePubli: {
            title:
              'Classification of glomerular hypercellularity using convolutional features and support vector machine.',
            link: 'https://www.sciencedirect.com/science/article/pii/S0933365719303148?via%3Dihub',
          },
          description:
            'Paulo Chagas, Luiz Souza, Ikaro Araújo, Nayze Aldeman, Angelo Duarte, Michele Angelo, Washington L.C. dos-Santos, Luciano Oliveira. Classification of glomerular hypercellularity using convolutional features and support vector machine, Artificial Intelligence in Medicine, Volume 103, 2020, 101808, ISSN 0933-3657. doi:10.1016/j.artmed.2020.101808',
          links: {
            sourceCode: '',
            downloadCode: 'https://www.sciencedirect.com/science/article/pii/S0933365719303148',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomeruli are histological structures of the kidney cortex formed by interwoven blood capillaries, and are responsible for blood filtration. Glomerular lesions impair kidney filtration capability, leading to protein loss and metabolic waste retention. An example of lesion is the glomerular hypercellularity, which is characterized by an increase in the number of cell nuclei in different areas of the glomeruli. Glomerular hypercellularity is a frequent lesion present in different kidney diseases. Automatic detection of glomerular hypercellularity would accelerate the screening of scanned histological slides for the lesion, enhancing clinical diagnosis. Having this in mind, we propose a new approach for classification of hypercellularity in human kidney images. Our proposed method introduces a novel architecture of a convolutional neural network (CNN) along with a support vector machine, achieving near perfect average results on FIOCRUZ data set in a binary classification (lesion or normal). Additionally, classification of hypercellularity sub-lesions was also evaluated, considering mesangial, endocapilar and both lesions, reaching an average accuracy of 82%. Either in binary task or in the multi-classification one, our proposed method outperformed Xception, ResNet50 and InceptionV3 networks, as well as a traditional handcrafted-based method. To the best of our knowledge, this is the first study on deep learning over a data set of glomerular hypercellularity images of human kidney.',
          },
        },
      ],
    },
  },
  {
    publicationPeriod: {
      year: '2019',
      publications: [
        {
          id: 'pub2019-1',
          titlePubli: {
            title:
              'Detecção Automática de Glomérulos em Imagens Histológicas Renais Digitais.',
            link: 'https://zenodo.org/record/3459948#.Ythjsy8w1B1',
          },
          description:
            'REHEM, J. M. C. ; ANGELO, M. F. ; SANTOS, W. L. C. Detecção Automática de Glomérulos em Imagens Histológicas Renais Digitais. In: Anais do XII Simpósio de Engenharia Biomédica - IX Simpósio de Instrumentação e Imagens Médicas, 2019. doi:110.5281/zenodo.3459947',
          links: {
            sourceCode: '',
            downloadCode:
              'https://zenodo.org/record/3459948/files/paper_58.pdf?download=1',
          },
          abstract: {
            title: 'Abstract',
            description:
              'Glomerulopathies are kidney diseases that affects thousands people in Brazil and in the entire world, this number is growing constantly. Technological evolution has allowed the digitalization of histological slides, making possible the development of computational tools that can assist the work of pathologists. This work aims to investigate if traditional machine learning techniques are efficient in the automatic detection of glomeruli, when applied over a proper dataset, composed by images captured by a different and heterogeneous method. For this, were used SVM (Support Vector Machines) classifier, mrcLBP (Multi Radial Color Local Binary Pattern) feature extractor, Sliding Window scan and Pyramid Image. This work achieved recall of 0.595, precision of 0.981 and 0.741 of F1 Score. This paper demonstrates the obstacles encountered and contributes to the advances needed to achieve this goal.',
          },
        },
      ],
    },
  },
  {
    publicationPeriod: {
      year: '2017',
      publications: [
        {
          id: 'pub2017-2',
          titlePubli: {
            title:
              'Automated Detection of Segmental Glomerulosclerosis in Kidney Histopathology.',
            link: 'https://sbic.org.br/eventos/cbic_2017/cbic-paper-10/',
          },
          description:
            'Ikaro Campos de Araújo, Leizer Schnitman, Angelo Amâncio Duarte, and Washington L.C. dos-Santos. Automated Detection of Segmental Glomerulosclerosis in Kidney Histopathology. In: Proceedings of XIII Brazilian Conference on Computational Intelligence – CBIC 2017. doi:10.21528/CBIC2017-10',
          links: {
            sourceCode: '',
            downloadCode:
              'https://sbic.org.br/wp-content/uploads/2018/09/cbic-paper-10.pdf',
          },
          abstract: {
            title: 'Abstract',
            description:
              'This paper presents the current results in the detection of segmental glomerulosclerosis by analyzing histological images of kidney biopsies, stained using hematoxylin and eosin (H&E) or periodic acidSchiff (PAS) techniques. The work is part of the development of the PathoSpotter-K system, which aims the detection of elemental lesions in histological images of kidney. Currently, PathoSpotter-K accuracy for detecting segmental glomerulosclerosis is 84.8% for H&E stained samples and 81.3% for PAS stained samples. Such rates are similar to that reported for most of the analogous systems used for histological lesions detection in other organs and diseases.',
          },
        },
        {
          id: 'pub2017-1',
          titlePubli: {
            title:
              'PathoSpotter-K: A computational tool for the automatic identification of glomerular lesions in histological images of kidneys.',
            link: 'https://www.nature.com/articles/srep46769',
          },
          description:
            'Barros, G., Navarro, B., Duarte, A. et al. PathoSpotter-K: A computational tool for the automatic identification of glomerular lesions in histological images of kidneys. Sci Rep 7, 46769 (2017). doi:10.1038/srep46769',
          links: {
            sourceCode: '',
            downloadCode: 'https://www.nature.com/articles/srep46769.pdf',
          },
          abstract: {
            title: 'Abstract',
            description:
              'PathoSpotter is a computational system designed to assist pathologists in teaching about and researching kidney diseases. PathoSpotter-K is the version that was developed to detect nephrological lesions in digital images of kidneys. Here, we present the results obtained using the first version of PathoSpotter-K, which uses classical image processing and pattern recognition methods to detect proliferative glomerular lesions with an accuracy of 88.3 ± 3.6%. Such performance is only achieved by similar systems if they use images of cell in contexts that are much less complex than the glomerular structure. The results indicate that the approach can be applied to the development of systems designed to train pathology students and to assist pathologists in determining large-scale clinicopathological correlations in morphological research.',
          },
        },
      ],
    },
  },
  {
    publicationPeriod: {
      year: '2015',
      publications: [
        {
          id: 'pub2015-1',
          titlePubli: {
            title:
              'Pathospotter: Um sistema para classificação de glomerulopatias a partir de imagens histológicas renais',
            link: 'http://sibgrapi.sid.inpe.br/rep/8JMKD3MGPBW34M/3K24PF8?ibiurl.backgroundlanguage=en&parentidentifiercitedby=6qtX3pFwXQZeBBx/pBvav',
          },
          description:
            'BARROS, G. O.; DUARTE, Â. A.; SANTOS, W. L. C. PathoSpotter: Um Sistema para Classificação de Glomerulopatias a partir de Imagens Histológicas Renais. In: CONFERENCE ON GRAPHICS, PATTERNS AND IMAGES, 28. (SIBGRAPI), 2015, Salvador, BA, Brazil. Proceedings... Porto Alegre: Sociedade Brasileira de Computação, 2015. On-line. IBI: <8JMKD3MGPBW34M/3JRK2U5>.',
          links: {
            sourceCode: '',
            downloadCode: 'http://urlib.net/ibi/8JMKD3MGPBW34M/3JRK2U5',
          },
          abstract: {
            title: 'Abstract',
            description:
              'This paper describes the current state of the research and implementation of PathoSpotter-K, a classification system of glomerulopathies based on histological images from kidney. The process of identify such pathologies from images requires pathologists with great expertise in image classification, because the features of the histological images lead to a subjective analysis. Currently, the PathoSpotter-K yields classifications with 67% accuracy. Other improvements are being implemented to increase the accuracy as also as to collect more images to build a larger dataset in order to assess robustness of the system.',
          },
        },
      ],
    },
  },
];
